import React from 'react';
import { Link } from 'react-router-dom';

const AllPDFView = () => {
    return (
        <div>
            <style>{`
                .pdf {
                    width: 100%;                    
                    height: 100%;
                    aspect-ratio: 4 / 3;
                    margin: 0;
                    padding: 0;
                }
            `}</style> 
 
            <h1>
                <b className='mx-3'> Social Media Service price list </b>
                <Link
                    className='kib_inside_effect text-[#8a92a5] rounded-md py-3 px-3 my-3 inline-block'
                    to='/AllServices'
                    >
                        Go Back
                </Link> 
            </h1>
           
            {/* PDF viewer section using <object> */}

            <object 
                className="pdf" 
                data="https://trollybazar.com/wp-content/uploads/2024/11/My_All_Services_List.pdf" 
                width="800" 
                height="500"
            >
                <p>Your browser does not support PDFs View. 
                <a href="https://trollybazar.com/wp-content/uploads/2024/11/My_All_Services_List.pdf">Download the PDF</a>.</p>
            </object>


            <h1>
                <b className='mx-3'> Social Media Service price list </b>
                <Link
                    className='kib_inside_effect text-[#8a92a5] rounded-md py-3 px-3 my-3 inline-block'
                    to='/AllServices'
                    >
                        Go Back
                </Link> 
            </h1>

        </div>
    );
};

export default AllPDFView;