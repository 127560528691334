import { useEffect, useState } from 'react';

const useMyToolsData = () => {
    const [myTools, setMyTools] = useState([]);

    useEffect(()=>{
        fetch('https://raw.githubusercontent.com/kibria-khandaker/API-Data/main/myToolsData.json')
        .then(res=>res.json())
        .then(data=>setMyTools(data))
    },[])

    return [myTools, setMyTools];
}

export default useMyToolsData;

