import React from 'react';
import ContactFormDiscussionAboutProject from './ContactFormDiscussionAboutProject';
import ContactFormRequestMe from './ContactFormRequestMe';
import { FaWhatsappSquare } from 'react-icons/fa';
import SocialBtnIcon from './../Common/SocialBtnIcon';

const Contact = () => {
    return (
        <div className=' text-center'>
            <div className="my-12">
                {/* <div className="kib_box_shadow rounded-3xl w-5/12 mx-auto">
                    <h1 className='gradient_text_box text-2xl px-2 sm:px-4 py-7'> Send Email with the option </h1>
                </div> */}

                <h2 className=' text-center text-xl md:text-3xl text-[#8a92a5] font-bold mt-20 underline underline-offset-8 decoration-0 decoration-dotted  hover:decoration-2 '> Talk to start your project </h2>


                <div className=' rounded-md p-5  my-10 flex justify-center items-center w-[200px] mx-auto gap-3  text-2xl text-[#8a92a5] kib_inside_effect'> <FaWhatsappSquare className=' text-green-500' /> <a href="https://wa.me/+8801913595268"> WhatsApp</a> </div>

                
                <SocialBtnIcon></SocialBtnIcon>


                {/* <p className='text-[#8a92a5] pt-7 pb-12'>
                    Please send me massage for Start Your Project with me Or your any inquiry
                </p> */}

                {/* ---- Both Modal Open Btn --------  */}
                {/* <label htmlFor="contactFormDiscussionModal" className="kib_inside_effect py-3 px-5 text-[#8a92a5] bg-[#ebecf0] outline-none border-0 rounded cursor-pointer">
                    Discussion About Your Project
                </label> */}


                {/* <br /> <br /> <br /> */}
                {/* <label htmlFor="contactFormRequestModal" className="kib_inside_effect py-3 px-5 text-[#8a92a5] bg-[#ebecf0] outline-none border-0 rounded cursor-pointer">
                    For any Request/Inquiry
                </label> */}

            </div>



            {/* ------------------------ All Modal body start */}
                {/* - Modal body for Discussion Contact form -  */}
                <input type="checkbox" id="contactFormDiscussionModal" className="modal-toggle" />
                <div className="modal modal-bottom sm:modal-middle">
                    <div className="modal-box">
                        <h3 className="font-light text-lg text-[#798192] ">
                            Contact with me for <span className='font-semibold'> Discussion</span> About your Project
                        </h3>
                        <ContactFormDiscussionAboutProject />
                        <div className="modal-action">
                            <label htmlFor="contactFormDiscussionModal"
                                className="kib_inside_effect text-[#fd4475] py-2 px-4 rounded">
                                Close
                            </label>
                        </div>
                    </div>
                </div>

                {/* - Modal body for Request/Inquiry Contact form -  */}
                <input type="checkbox" id="contactFormRequestModal" className="modal-toggle" />
                <div className="modal modal-bottom sm:modal-middle">
                    <div className="modal-box">
                        <h3 className="font-light text-lg text-[#798192] ">
                            Contact With me for any <span className=' font-semibold'>Request/Inquiry</span>
                        </h3>
                        <ContactFormRequestMe />
                        <div className="modal-action">
                            <label htmlFor="contactFormRequestModal"
                                className="kib_inside_effect text-[#fd4475] py-2 px-4 rounded">
                                Close
                            </label>
                        </div>
                    </div>
                </div>
            {/* ------------------------ All Modal body end  */}

            {/* <br /> */}

            <img className=' w-40 mx-auto' src="https://raw.githubusercontent.com/kibria-khandaker/kibria-khandaker/main/img/my_qrcode.png" alt="" />

        </div>
    );
};

export default Contact;