import { useEffect, useState } from 'react';

const useBlogs = () => {
    const [posts, setPosts] = useState([]);

// AIzaSyCPPVgk_mZzohMpOzzbNYyz-sUuYceZfkU
// AIzaSyAkvg1tzTwKsVbdmuKDsOxP2uEmAxZYVg4
//a-p:  8567499219144473878
//s-p:   5756455846670167424
// const aKey = process.env.GOOGLEBLOGAPI;
// const aKeyID = process.env.BLOGGERSITEID;

// console.log(process.env.GOOGLE_USER_API_KEY);
// console.log(process.env.BLOGGER_SITE_ID);

    useEffect(()=>{
        fetch('https://www.googleapis.com/blogger/v3/blogs/8567499219144473878/posts?key=AIzaSyAkvg1tzTwKsVbdmuKDsOxP2uEmAxZYVg4')
        .then(res=>res.json())
        // .then(data=>setPosts(data))
        .then(data=>setPosts(data.items))
    },[])

    return [posts, setPosts];
}
export default useBlogs;