import React from 'react';
import netKibLogo from '../../../Assets/img/NetKib.png';
import SocialBtnIcon from '../../Common/SocialBtnIcon';
import PartnershipsBtnIcon from './PartnershipsBtnIcon';

const NetkibHomeSide = () => {
    return (
        <>
            <img className="w-[160px] h-auto md:mb-5 mx-auto kib_box_shadow rounded-r hover:-translate-y-1 hover:scale-105 duration-200 grayscale-0 hover:grayscale" src={netKibLogo} alt='myImg' /> 

            <SocialBtnIcon></SocialBtnIcon>

            <PartnershipsBtnIcon></PartnershipsBtnIcon>


        </>
    );
};

export default NetkibHomeSide;