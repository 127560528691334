import React, { useState, useMemo } from 'react';
import useAllServiceData from '../../hooks/useAllServiceData';
import AllServiceDetailsModal from './AllServiceDetailsModal';

const AllServices = () => {
    const [myAllServices] = useAllServiceData();
    const [modalAllServiceData, setModalAllServiceData] = useState(null);
    const [sortOption, setSortOption] = useState('random'); // Default sort option set to 'random'

    const maxTextLength = 80;
    const truncateText = (text) => {
        return text.length <= maxTextLength ? text : text.slice(0, maxTextLength) + '...';
    };

    // Handle sorting based on selected option
    const handleSortChange = (event) => {
        setSortOption(event.target.value);
    };

    // Shuffle function for random sorting
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    // Memoized sorted services to re-calculate only when myAllServices or sortOption changes
    const sortedServices = useMemo(() => {
        const sortedArray = [...myAllServices];
        switch (sortOption) {
            case 'id-asc':
                sortedArray.sort((a, b) => a._id - b._id);
                break;
            case 'id-desc':
                sortedArray.sort((a, b) => b._id - a._id);
                break;
            case 'name-asc':
                sortedArray.sort((a, b) => a.s_Title.localeCompare(b.s_Title));
                break;
            case 'name-desc':
                sortedArray.sort((a, b) => b.s_Title.localeCompare(a.s_Title));
                break;
            case 'random':
                shuffleArray(sortedArray);
                break;
            default:
                break;
        }
        return sortedArray;
    }, [myAllServices, sortOption]);

    return (
        <div>
            <h2 className="text-center text-3xl text-[#8a92a5] font-bold mt-16 underline underline-offset-8 decoration-0 decoration-dotted hover:decoration-2">
                All Services
            </h2>
            <p className="text-[#7a8190] mt-5 mb-8 text-center w-3/5 mx-auto">
                Providing the best and trusted service for any client.
            </p>

            {/* Sort Options */}
            <div className="text-right mb-8 pr-5 w-full">
                <select onChange={handleSortChange} value={sortOption} className="rounded-md gradient_text_box kib_inside_effect py-3 pl-2">
                    <option value="random">Sort by default (Random)</option>
                    <option value="id-asc">Sort by: ASC</option>
                    <option value="id-desc">Sort by: DSC</option>
                    <option value="name-asc">Sort by: A to Z</option>
                    <option value="name-desc">Sort by: Z to A</option>
                </select>
            </div>

            {/* Display sorted services */}
            <div className="w-full p-5 grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 gap-14">
                {sortedServices.map(myService => (
                    <div key={myService._id} className="card card-compact rounded-lg shadow-xl kib_box_shadow relative group kib_w_bg">
                        <figure className="lg:h-44">
                            <img src={myService.s_Img} alt={myService.s_Title || "Service Image"} />
                        </figure>

                        <div className="text_pro p-2 m-2 rounded bg-[#ffffff] bg-opacity-90 absolute top-0 left-0 right-0 bottom-0 z-10 hidden group-hover:block">
                            <a 
                                target="_blank" 
                                rel="noreferrer" 
                                aria-label="Project Live Website link"
                                className="kib_inside_effect rounded-none font-semibold text-center py-1 px-3 text-[#7a8190] block" 
                                href={myService.s_ContactUrl}
                            > Contact For Service 
                            </a>

                            <p className="text-center text-base mt-2 text-[#3e434f] leading-[18px]">
                                {truncateText(myService.s_Slogan)}
                            </p>

                            <label 
                                onClick={() => setModalAllServiceData(myService)} 
                                htmlFor="productDetailsModal" 
                                className="kib_inside_effect rounded-none font-semibold text-center py-1 px-3 text-[#7a8190] w-full absolute bottom-0 left-0 right-0 cursor-pointer"
                            > Check Details 
                            </label>
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal for displaying service details */}
            {/* <AllServiceDetailsModal modalAllServiceData={modalAllServiceData}></AllServiceDetailsModal> */}
            <AllServiceDetailsModal 
                modalAllServiceData={modalAllServiceData} 
                onClose={() => setModalAllServiceData(null)} 
            />
        </div>
    );
};

export default AllServices;


