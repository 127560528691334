import React, { useState } from 'react';

const PartnershipsImg = ({ P_label, P_link, P_img, P_alt, P_name }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <a
            target="_blank"
            rel="noreferrer"
            aria-label={P_label}
            className="kib_box_shadow text-[#777f93] hover:text-[#0ab299] rounded-md relative"
            href={P_link}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <img
                className="w-[60px] h-auto mx-auto rounded-r hover:-translate-y-1 hover:scale-105 duration-200 grayscale-0 hover:grayscale"
                src={P_img}
                alt={P_alt}
            />
            {isHovered && (
                <span className="absolute bottom-full right-0 left-0 mb-1 bg-[#ffffffd2] text-[#0ab299] t rounded text-xs">
                    {P_name}
                </span>
            )}
        </a>
    );
};

export default PartnershipsImg;
