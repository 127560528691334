import React from 'react';
import kibriaLogo from '../../../Assets/img/kibriaLogo.png';
import netKibLogo from '../../../Assets/img/NetKib.png';
import SocialBtnIcon from '../../Common/SocialBtnIcon';
import PartnershipsBtnIcon from '../HomeNetKibSections/PartnershipsBtnIcon';

const CommonHomeServiceItems = () => {
    return (
        <>
            <img className="w-[100px] h-auto md:mb-5 mx-auto rounded-r hover:-translate-y-1 hover:scale-105 duration-200 grayscale-0 hover:grayscale" src={netKibLogo} alt='myImg' />
            <img className="w-[100px] h-auto md:mb-3 mx-auto rounded-r hover:-translate-y-1 hover:scale-105 duration-200 grayscale-0 hover:grayscale" src={kibriaLogo} alt='myImg' />
            <SocialBtnIcon></SocialBtnIcon>
            <PartnershipsBtnIcon></PartnershipsBtnIcon>
        </>
    );
};

export default CommonHomeServiceItems;
