import React from 'react';

const ResumeDownloadBtn = () => {
    return (
        <a target='_blank' rel="noreferrer"
            aria-label="My Resume Download Link Here"
            className='kib_inside_effect text-[#7a8190] rounded inline-block mt-8 mb-3 py-4 px-3' href="https://drive.google.com/file/d/1fTmHAU_bHKjG9oRlfnANjT3_hmvumoP_/view?usp=sharing" >
            Download Resume
        </a>
    );
};

export default ResumeDownloadBtn;