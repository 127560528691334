import React from 'react';
import myImg from '../../../Assets/img/FrontEnd-Developer.avif';
import SocialBtnIcon from '../../Common/SocialBtnIcon';
import ResumeDownloadBtn from '../../Common/ResumeDownloadBtn';
import PartnershipsBtnIcon from '../HomeNetKibSections/PartnershipsBtnIcon';
const KibriaHomeSide = () => {
    return (
        <>
            <img className="md:w-[140px] w-[180px] h-auto mx-auto kib_box_shadow rounded-full hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 grayscale hover:grayscale-0" src={myImg} alt='myImg' />

            <ResumeDownloadBtn></ResumeDownloadBtn>
            <SocialBtnIcon></SocialBtnIcon>
            <PartnershipsBtnIcon></PartnershipsBtnIcon>
        </>
    );
};

export default KibriaHomeSide;