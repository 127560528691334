import React from 'react';
import { FaGithubSquare, FaLinkedin, FaWhatsappSquare } from "react-icons/fa";
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className='kib_box_shadow w-12/12  md:w-8/12 max-w-screen-lg mx-5  md:mx-10 mt-9 rounded-lg py-10 px-5 md:px-20 text-[#787f90]'>
            <h2 className=' text-center text-4xl my-10 font-bold  underline underline-offset-8 decoration-0 decoration-dotted text-[#0ab299] hover:decoration-2 '> Here is not found what you want </h2>
            
            <div className='text-center flex pt-5 flex-col gap-5 '>
                {/* <p> My name is <b>Golam kibria</b>, I am able to work with <b>"Frontend" "Backend"</b> and <b>"WordPress"</b> </p> */}

                <div className='flex gap-4 justify-center'>
                    <Link
                        className='kib_inside_effect text-[#8a92a5] rounded-md py-3 px-3 my-3 inline-block'
                        to='/'
                        >
                            Go Home
                    </Link> 

                    <Link
                        className='kib_inside_effect text-[#8a92a5] rounded-md py-3 px-3 my-3 inline-block'
                        to='/AllServices'
                        >
                            Go Services
                    </Link> 

                    <Link
                        className='kib_inside_effect text-[#8a92a5] rounded-md py-3 px-3 my-3 inline-block'
                        to='/MyPortfolio'
                        >
                            All Project
                    </Link>
                </div> 


                <div className=" flex  justify-center pb-4 gap-5 ">
                    <a target='_blank' rel="noreferrer" aria-label="My Linkedin Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://www.linkedin.com/in/kibria-khandaker/" >
                        <FaGithubSquare />
                        <span className="tooltiptext2 text-sm "> Linkedin </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My Twitter Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://github.com/kibria-khandaker" >
                        <FaLinkedin />
                        <span className="tooltiptext2 text-sm "> Twitter </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My WhatsApp Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://wa.me/+8801913595268" >
                        <FaWhatsappSquare />
                        <span className="tooltiptext2 text-sm "> WhatsApp </span>
                    </a>
                </div>

            </div>
        </div>
    );
};


export default NotFound;