import React, { useState, useMemo } from 'react';
import useProjectData from '../../hooks/useProjectData';
import MyProjectDetailsModal from './MyProjectDetailsModal';

const maxTextLength = 140;
const truncateText = (text) => {
    return text.length <= maxTextLength ? text : text.slice(0, maxTextLength) + '...';
};

// Shuffle function for random sorting, avoiding mutation
const shuffleArray = (array) => {
    const shuffledArray = [...array];
    shuffledArray.sort(() => Math.random() - 0.5);
    return shuffledArray;
};

const MyPortfolio = () => {
    const [projects] = useProjectData();
    const [modalProjectData, setModalProjectData] = useState(null);
    const [sortOption, setSortOption] = useState('random'); // Default to random sorting

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
    };

    // Memoized sortedProjects to re-calculate only when projects or sortOption changes
    const sortedProjects = useMemo(() => {
        const sortedProjects = [...projects];
        switch (sortOption) {
            case 'id-asc':
                sortedProjects.sort((a, b) => a._id - b._id);
                break;
            case 'id-dsc':
                sortedProjects.sort((a, b) => b._id - a._id);
                break;
            case 'name-asc':
                sortedProjects.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case 'name-dsc':
                sortedProjects.sort((a, b) => b.name.localeCompare(a.name));
                break;
            case 'random':
                return shuffleArray(sortedProjects);
            default:
                break;
        }
        return sortedProjects;
    }, [projects, sortOption]); // Only re-compute when these change

    return (
        <div>
            <h2 className='text-center text-3xl text-[#8a92a5] font-bold mt-16 underline underline-offset-8 decoration-0 decoration-dotted hover:decoration-2'>
                All Portfolio
            </h2>

            <p className='text-[#7a8190] mt-5 mb-14 text-center w-3/5 mx-auto'>
                Here some projects with all details.
            </p>

            {/* Sorting dropdown */}
            <div className="text-right mb-8 pr-5 w-full">
                <select onChange={handleSortChange} value={sortOption} className="rounded-md gradient_text_box kib_inside_effect py-3 pl-2">
                    <option value="random">Sort by default (Random)</option>
                    <option value="id-asc">Sort by: ASC</option>
                    <option value="id-dsc">Sort by: DSC</option>
                    <option value="name-asc">Sort by: A to Z</option>
                    <option value="name-dsc">Sort by: Z to A</option>
                </select>
            </div>

            {/* Project items section */}
            <div className='w-full p-5 grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 gap-14'>
                {sortedProjects.map(project => (
                    <div key={project._id} className="card card-compact bg-base-100 rounded-lg shadow-xl kib_box_shadow relative group">
                        <figure className='lg:h-64'>
                            <img src={project.img1} alt="project_Image" />
                        </figure>

                        <div className='text_pro p-2 m-2 rounded bg-[#ffffff] bg-opacity-90 absolute top-0 left-0 right-0 bottom-0 z-10 group-hover:block hidden'>
                            <a 
                                target='_blank' 
                                rel="noreferrer" 
                                aria-label="Project Live Website link"
                                className='kib_inside_effect rounded-none font-semibold text-center py-1 px-3 text-[#7a8190] block' 
                                href={project.p_ContactUrl}
                            > Contact For Service 
                            </a>

                            <p className='text-center text-base mt-2 text-[#3e434f] leading-[18px]'> 
                                <b> {project.name} </b> <br/>
                                {truncateText(project.technology)}
                            </p>

                            <label 
                                onClick={() => setModalProjectData(project)} 
                                htmlFor="productDetailsModal" 
                                className="kib_inside_effect rounded-none font-semibold text-center py-1 px-3 text-[#7a8190] w-full absolute bottom-0 left-0 right-0 cursor-pointer"
                            > Check Details 
                            </label>
                        </div>
                    </div>
                ))}
            </div>

            <MyProjectDetailsModal modalProjectData={modalProjectData} onClose={() => setModalProjectData(null)} ></MyProjectDetailsModal>
        </div>
    );
};

export default MyPortfolio;
