import { useEffect, useState } from 'react';

const useAllServiceData = () => {
    const [myAllServices, setMyAllServices] = useState([]);

    useEffect(()=>{
        fetch('https://raw.githubusercontent.com/kibria-khandaker/API-Data/main/myAllServicesListData.json')
        .then(res=>res.json())
        .then(data=>setMyAllServices(data))
    },[])

    return [myAllServices, setMyAllServices];
};

export default useAllServiceData;