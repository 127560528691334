import React from 'react';
import NetkibHomeSide from './../DomainBasedItems/HomeNetKibSections/NetkibHomeSide';
import KibriaHomeSide from './../DomainBasedItems/HomeKibriaSections/KibriaHomeSide';
import CommonHomeServiceItems from './../DomainBasedItems/BoothCommonSection/CommonHomeServiceItems';



const Home = () => {
    const currentDomain = window.location.hostname;
    return (
        <div className='w-11/12 max-w-screen-lg kib_box_shadow rounded-3xl mx-auto my-8 md:mx-10 py-8 px-6 lg:py-auto  '>

            <div className='md:flex justify-evenly items-center '>

                <div className=' text-center'>

                    {currentDomain === 'netkib.com' ? (
                        // ======== for netkib.com ========
                        <NetkibHomeSide></NetkibHomeSide>
                    ) : currentDomain === 'kibria.net' ? (
                        // ======== for kibria.net ========
                        <KibriaHomeSide></KibriaHomeSide>
                    ) : (
                        // <KibriaHomeSide></KibriaHomeSide>
                        // <NetkibHomeSide></NetkibHomeSide>
                        <CommonHomeServiceItems></CommonHomeServiceItems>
                    )}

                    {/* ======== for common ======== */}
                    

                </div>

                {/* --------------------------  */}
                <div className=' mt-14 md:mt-0 md:ml-6 flex flex-col text-center '>
                    <div className='kib_box_shadow pt-5 pb-7 px-6 md:px-8 rounded-lg  text-[#7a8190]'>
                        {/* ======== for common ======== */}
                        <div>
                            <h1 className='font-bold md:text-2xl pb-4' > A Complete Web Solution </h1>
                            <h2 className='md:font-semibold mb-6 text-[#8a92a5]'> WordPress and Frontend Development </h2>
                            <h3 className='font-normal text-xs mb-6 text-[#8a92a5]'> Want to work WordPress ( theme & plugin) development. & Frontend development, <br />React & Next.js application development. all social media platform setup & management. <br /> Have many project and experience about this related all field.</h3>
                        </div>

                        {/* ======== for netkib.com ======== */}
                        <div className=''>
                            <h4 className='md:font-semibold mt-12 mb-8 text-[#8a92a5]'> Giving Development & Digital marketing Service </h4>

                            <h5 className='md:font-semibold mb-6 text-[#8a92a5] kib_inside_effect  inline-block mx-3 p-3 rounded'> WordPress Development </h5>
                            <h5 className='md:font-semibold mb-6 text-[#8a92a5] kib_inside_effect  inline-block mx-3 p-3 rounded'>  React & Next.js Application  </h5> <br />
                            <h5 className='md:font-semibold mb-6 text-[#8a92a5] kib_inside_effect  inline-block mx-3 p-3 rounded'>  Front-end Development  </h5>
                            <h5 className='md:font-semibold mb-6 text-[#8a92a5] kib_inside_effect  inline-block mx-3 p-3 rounded'>  Social Media Setup & Management </h5>
                        </div>

                    </div>
                </div>

            </div>

            <div className='kib_box_shadow md:mt-5 mt-12 px-6 md:px-8 py-8 rounded-md text-[#7a8190]'>
                <h6 className='text-[#7a8190] text-sm text-center md:w-7/12 mx-auto'> <span className='animate-pulse text-slate-900'>
                    Working with all related <span className='text-green-500 font-semibold'>skills</span> </span>:<br></br>
                    WordPress (theme & Plugin development),
                    Next.js,
                    Reactjs,
                    Tailwind css,
                    Bootstrap css,
                    Photoshop,
                    Figma,
                    Javascript,
                    Typescript,
                    PHP,
                    Redux,
                    Node.js,
                    Express,
                    MongoDB,
                    Mongoosejs,
                    MySQL,
                    Prisma,
                    PostgreSQL,
                </h6>
            </div>
        </div>
    );
};

export default Home;