import React, { useState } from 'react';
import PartnershipsImg from './PartnershipsImg';

import TravelKib from '../../../Assets/img/TravelKib.png';
import TrollyBazar from '../../../Assets/img/TrollyBazar.png';
import KibriaNet from '../../../Assets/img/Kibria_Net.png';
import LondonEducationHub from '../../../Assets/img/LondonEducationHub.png';
import BDShiftingService from '../../../Assets/img/BDShiftingService.png';
import BDServiceTeam from '../../../Assets/img/BDServiceTeam.png';

const partnerships = [
    { P_label: "TravelKib Website", P_link: "https://travelkib.com", P_img: TravelKib, P_alt: "TravelKib", P_name: "Travel Kib" },
    { P_label: "TrollyBazar Website", P_link: "https://trollybazar.com", P_img: TrollyBazar, P_alt: "TrollyBazar", P_name: "Trolly Bazar" },
    { P_label: "Kibria's Website", P_link: "https://kibria.net", P_img: KibriaNet, P_alt: "KibriaNet", P_name: "Kibria Net" },
    { P_label: "London Education Hub Website", P_link: "https://londoneducationhub.co.uk", P_img: LondonEducationHub, P_alt: "LondonEducationHub", P_name: "London Education Hub" },
    { P_label: "BD Shifting Service Website", P_link: "https://bdshiftingservice.com", P_img: BDShiftingService, P_alt: "BDShiftingService", P_name: "BD Shifting Service" },
    { P_label: "BD Service Team Website", P_link: "https://bdserviceteam.com", P_img: BDServiceTeam, P_alt: "BDServiceTeam", P_name: "BD Service Team" }
];

const PartnershipsBtnIcon = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 2;

    const handleNext = () => {
        if (currentIndex + itemsPerPage < partnerships.length) {
            setCurrentIndex(currentIndex + itemsPerPage);
        }
    };

    const handlePrev = () => {
        if (currentIndex - itemsPerPage >= 0) {
            setCurrentIndex(currentIndex - itemsPerPage);
        }
    };

    const visiblePartnerships = partnerships.slice(currentIndex, currentIndex + itemsPerPage);

    return (
        <>
            <p className='font-semibold text-xl text-[#7a8190] mt-4 mb-6 md:text-left'>Collaborators</p>

            <div className="grid grid-cols-2 gap-5 mb-10 md:mb-0">
                {visiblePartnerships.map((partnership, index) => (
                    <PartnershipsImg
                        key={index}
                        P_label={partnership.P_label}
                        P_link={partnership.P_link}
                        P_img={partnership.P_img}
                        P_alt={partnership.P_alt}
                        P_name={partnership.P_name}
                    />
                ))}
            </div>

            <div className="flex justify-between mt-8 text-sm">
                <button
                    onClick={handlePrev}
                    disabled={currentIndex === 0}
                    className="px-2 py-1  kib_inside_effect rounded disabled:opacity-50"
                >
                    Previous
                </button>
                <button
                    onClick={handleNext}
                    disabled={currentIndex + itemsPerPage >= partnerships.length}
                    className="px-2 py-1  kib_inside_effect rounded disabled:opacity-50"
                >
                    Next
                </button>
            </div> 
        </>
    );
};

export default PartnershipsBtnIcon;
