// AllServiceDetailsModal.js file 
import React from 'react';
import { Link } from 'react-router-dom';

const AllServiceDetailsModal = ({ modalAllServiceData }) => {
    // console.log('projects', modalAllServiceData);
    return (
        <>
            <input type="checkbox" aria-label="product Details Modal" id="productDetailsModal" className="modal-toggle" />
            {/* border-4 border-red-600  */}
            <div className="modal">
                <div className="modal-box lg:w-8/12 lg:ml-32 w-12/12 max-w-4xl rounded-none shadow-2xl shadow-white  text-center">

                    <label htmlFor="productDetailsModal" className="kib_inside_effect text-[#fd4475]  px-4 py-2 rounded-full absolute right-5 top-5 flex justify-center items-center">✕</label>

                    <h3 className="font-semibold text-lg mt-5 mb-1 text-[#8a92a5] "> {modalAllServiceData?.s_Title} </h3>
                    <p className='px-5 mt-2 mb-8 text-[#98a0af]'>{modalAllServiceData?.s_Keywords}</p>

                    {/* =======================  */}

                    <img className='kib_box_shadow lg:w-3/5 w-full mx-auto kib_light_p_bg' src={modalAllServiceData?.s_Img} alt={modalAllServiceData?.s_Title || "Service Image"} />

                    {/* <a target='_blank' rel="noreferrer" aria-label="Project Live Website link" className='kib_inside_effect text-[#8a92a5] rounded-md py-3 px-3  mt-10 inline-block' href={modalAllServiceData?.s_ContactUrl} > Contact For the Service </a> */}


                    
                <h1 className='underline underline-offset-8 decoration-0 decoration-dotted  hover:decoration-2 text-[#8a92a5]  font-bold md:text-2xl mt-24 mb-10 px-2 inline-block mx-auto'> Check out our service plans and pricing </h1>
          


                    {/* <div className='mb-10  text-[#8a92a5]'><p>{modalAllServiceData?.s_ShortText}</p></div> */}
                    {
                        // console.log("Service Package Data:", modalAllServiceData?.s_ServicePackageAndList)
                    }
                    {
                        // console.log("Data passed to modal:", modalAllServiceData)
                    }

                    {/* ======================= grid lg:grid-cols-2 gap-10  */}

                    {/* Display Service Packages */}
                    <div className='grid lg:grid-cols-2  gap-10  text-[#6e7689] ServicePackage_box'>

                        <div className='text-left content-center '>
                            <p>{modalAllServiceData?.s_ShortText}</p>
                            <p>{modalAllServiceData?.packageShortText}</p>
                        </div>
                        {
                            // Convert s_ServicePackageAndList to an array and map over it
                            modalAllServiceData?.s_ServicePackageAndList ? (
                                Object.values(modalAllServiceData.s_ServicePackageAndList).map((packageItem, index) => (
                                    <div key={index} className='kib_box_shadow flex flex-col justify-between p-1 ServicePackage_repeat_box'>
                                        {/* {console.log("Service packageItem xx:", packageItem)} */}
                                        <ul className=' mb-1 md:mb-0 md:flex justify-between tracking-tight border-b-2 px-2 py-1 ServicePackage_name_price'>
                                            <li className='font-semibold'>  {packageItem.PlanType} </li>
                                            <li className='gradient_text_box font-semibold'> {packageItem.PlanName}   </li>
                                        </ul>
                                        <ul className='text-left list-inside p-4 ServicePackage_all_lists listStyleCheckMarkImg '>
                                            {packageItem.PlanLists && packageItem.PlanLists.map((listItem, idx) => (
                                                <li className='' key={idx}>{listItem}</li>
                                            ))}
                                        </ul>
                                        <ul className='md:flex justify-between border-t-2 px-2 py-1 md:py-2 tracking-tight ServicePackage_contact_link'>
                                            {/* <li>Contact</li> */}
                                            <li className='font-semibold mb-1 md:mb-0'> {packageItem.PlanPrice} :</li>
                                            <li>
                                                <a
                                                    target='_blank'
                                                    rel="noreferrer"
                                                    aria-label="Project Live Website link"
                                                    className='kib_inside_effect py-1 px-2 gradient_text_box rounded-sm'
                                                    href={packageItem.PlanContactLink}
                                                >
                                                    Contact For Service
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                ))
                            ) : (
                                <p className=' text-center '>No Service Packages Available</p>
                            )
                        }
                    </div>

                    <div className='grid lg:grid-cols-2 gap-10 mt-16'>
                        <img className='kib_box_shadow w-full' src={modalAllServiceData?.packageBannerImg} alt="ServiceImage" />
                        <img className='kib_box_shadow w-full' src={modalAllServiceData?.packageOfferImg} alt="ServiceImage" />
                    </div>

                    {/* =======================  */}

                    {/* <div className='grid lg:grid-cols-2 gap-10'>
                        {
                            modalAllServiceData?.s_AllImg.map((arryImg, index) => (
                                <img key={index} className='kib_box_shadow w-full' src={arryImg} alt="ServiceImage" />
                            ))
                        }
                    </div> */}

                    {/* ====================  */}

                    <a target='_blank' rel="noreferrer"
                        aria-label="Project Live Website link"
                        className='kib_inside_effect text-[#8a92a5] rounded-md py-3 px-3  my-16 inline-block' href={modalAllServiceData?.s_ContactUrl} > Contact For the Service </a>
                    <p className='px-5 text-[#98a0af] text-left'>{modalAllServiceData?.s_Description}</p>


                    <Link
                        className='kib_inside_effect text-[#8a92a5] rounded-md py-3 px-3  mt-16 inline-block'
                        to='/Social-Media-Pdf'
                    >
                        Check Social Media Pdf
                    </Link>
                    {/* =======================  */}



                    {/* close btn */}
                    <div className="modal-action">
                        <label htmlFor="productDetailsModal" className="kib_inside_effect text-[#fd4475]   px-4 py-2 rounded-md my-10 mr-4">close</label>
                    </div>

                </div>
            </div>
        </>
    );
};

export default AllServiceDetailsModal;