import React from 'react';
// import { Link } from 'react-router-dom';
import useBlogs from './../../hooks/useBlogs';

const Blogs = () => {
    const [posts, setPosts] = useBlogs()
    // console.log(posts);

    // var noHTML =  OriginalString.replace(/(<([^>]+)>)/ig,"");
    return (
        <div>

            {/* <div className="kib_box_shadow bg-[#ebecf0] mt-12 mb-14 text-center rounded-2xl w-4/5 lg:w-2/5 mx-auto">
                <h1 className='gradient_text_box text-2xl md:text-3xl py-8 px-2'> knowledgeable topic </h1>
            </div> */}

            <h2 className=' text-center text-3xl text-[#8a92a5]  font-bold mt-20 mb-12 underline underline-offset-8 decoration-0 decoration-dotted  hover:decoration-2 '> Knowledgeable Topic </h2>

            <div className='w-full p-5 grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 gap-10'>
                {
                    posts.map(post => (
                        <div key={post.id} className="kib_box_shadow kib_box_shadow_hover bg-[#ebecf0] text-[#8a92a5]  p-4 rounded relative group ">
                            <h4 className='p-0 pb-3 m-0'> {post.title} </h4>

                            {/* <p>{post.content.replace(/(<([^>]+)>)/ig,"")}</p>  */}
                            {/* <p>{post.content}</p>  */}

                            <a className='absolute text-[#0ab299] group-hover:text-[#8a92a5] hover:font-bold  left-4 bottom-1' href={post.url} target="_blank" rel="noreferrer"  > Read Content ... </a>
                            {/* <Link
                                        className='social-icon-card-github'
                                        to={{ pathname: "https://github.com" }} target="_blank">
                                        Read Content
                                    </Link> */}

                            {/* <p>{post.content.rendered.replace(/(<([^>]+)>)/ig,"")}</p> */}
                        </div>
                    ))
                }
            </div>

        </div >
    );
};

export default Blogs;